import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useExpenseDayDetail = () => {
  const { state: expenseState } = useLocation();
  const [expenseData, setExpenseData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!expenseState || !expenseState?.data) {
      navigate("/");
      return;
    }
    setExpenseData(expenseState.data);
  }, [expenseState]);

  const columns = useMemo(() => {
    let template = [
      {
        name: "Date",
        selector: (row) => row.date,
        wrap: true,
      },
      {
        name: "Expense Type",
        selector: (row) => row.expenseWay?.split("_").join(' '),
        wrap: true,
      },
      {
        name: "Expense Amount",
        selector: (row) => row.amount,
        wrap: true,
      },
      {
        name: "Spent At",
        selector: (row) => row.spentAt ?? 'N/A',
        wrap: true,
      },
    ];
    return template;
  }, [expenseData]);

  return {
    expenseData,
    columns,
    navigate
  };
};
